import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TopBannerChar extends Component {
    render() {
        const {image, name, nickname, desc, country} = this.props;
        return (
            <div id="TopBannerChar">
                <div className="desktop">
                    <img className="char_img" src={image} alt=""/>
                    <div className="text-cont">
                        <p className="text-cont__title">{nickname}</p>
                        <img src={require('assets/img/karakter/Shape_Name.png')} alt=""/>
                        <p className="text-cont__name">{name}</p>
                        <p className="text-cont__description">{desc}</p>
                        <div className="flag-cont">
                            <div className="flag">
                                <div className="flag__name">{country.name}</div>
                                <img src={country.flag} alt="" className="flag__image"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile">
                    <div className="char_img" style={{
                        backgroundImage: `url("${image}")`
                    }}></div>
                    <div className="text-container">
                        <img src={require('assets/img/karakter/Placeholder_Karakter-Mobile.png')} alt=""/>
                        <div className="text-cont">
                            <p className="text-cont__title">{nickname}</p>
                            <img src={require('assets/img/karakter/Shape_Name.png')} alt=""/>
                            <p className="text-cont__name">{name}</p>
                            <p className="text-cont__description">{desc}</p>
                            <div className="flag-cont">
                                <div className="flag">
                                    <div className="flag__name">{country.name}</div>
                                    <img src={country.flag} alt="" className="flag__image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TopBannerChar.propTypes = {
    image : PropTypes.string,
    name : PropTypes.string,
    desc : PropTypes.string,
    // country : PropTypes.string
}

export default TopBannerChar;
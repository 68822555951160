export const put_data_char = (key, data) => ({
    type: 'PUT_DATA_CHAR',
    key,
    data
})

export const init_data = (data, param) => ({
    type: 'INIT_DATA_CHAR',
    data,
    param
})

export const change_tab_char = (id) => ({
    type: 'CHANGE_TAB_CHAR',
    id
})
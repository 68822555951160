import React, { Component } from 'react';
import {graphql} from 'gatsby';
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import TopBannerChar from 'partials/TopBannerChar';
import Layout from 'layout';
import { getUrlParam } from 'helper';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as characterActions from 'redux/actions/character';

class Character extends Component {

    componentDidMount() {
        const {char} = this.props.data;
        const {init_data} = this.props.characterActions;
        const c = getUrlParam("c");
        init_data(char, c);
    }

    populateChar = () => {
        const {char} = this.props.data;
        const {change_tab_char} = this.props.characterActions;
        const {current_data} = this.props.character;

        return char.edges.map((data,index) => 
            <div 
                key={index} 
                className={`list ${current_data && current_data.node.directusId == data.node.directusId ? "active": ""}`}
                onClick={() => change_tab_char(data.node.directusId)}
            >
                <img src={data.node.thumbnail} alt=""/>
            </div>
        )
    }

    render() {
        const {current_data} = this.props.character;
        return (
            <Layout>
                <Helmet title={"Character - " + config.siteTitle} />
                <div id="Character" className="content-container">
                    <TopBannerChar
                        image={(current_data) ? current_data.node.banner : ""} 
                        name={(current_data) ? current_data.node.name : ""}
                        nickname={(current_data) ? current_data.node.nickname : ""}
                        // desc="Ahli jagal hewan"
                        desc={(current_data) ? current_data.node.description : ""}
                        country={(current_data) ? current_data.node.country.data : ""}
                    />
                    <div className="content">
                        <div className="content__title">Daftar Karakter</div>
                        <div className="char-list">
                            {this.populateChar()}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
	return { character: state.character }
}

function mapDispatchToProps(dispatch) {
	return {
		characterActions: bindActionCreators(characterActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Character); 

export const pageQuery = graphql`
{
    char: allDirectusCharacter {
      edges {
        node {
          directusId
          name
          nickname
          description
          thumbnail
          banner
          country {
            data {
              name
              flag
            }
          }
        }
      }
    }
  }  
`;